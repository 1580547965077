;(function($, window) {

	var App = {};

	App.breakpoints = {
		xs : 480,
		sm: 768,
		md: 992,
		md2: 1260,
		lg: 1460,
		xl: 1620
	};

	App.gridGutterWidth = 30;
	App.isMobile = $('html').hasClass('mobile');
	App.isTablet = $('html').hasClass('tablet');
	App.isDesktop = $('html').hasClass('desktop');


	var BEHAVIOUR_PREFIX = "js",
		BEHAVIOUR = {},
		GLOBAL_BEHAVIOURS = [];

	BEHAVIOUR.addToWishlist = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__addToWishlist'))
				return;
			_this.data('__addToWishlist',true);

			_this.loading = false;

			_this.$modal = $('#modal-add-to-whishlist');
			_this.$action = _this.find('.add-to-wishlist__action');

			_this.$modal.appendTo('body').modal({show: false});

			_this.$modal.on('show.bs.modal', function(e) {
		    	centerModal();
		    	$(window).bind('resize.modal', function() {
		    		centerModal();
		    	});
			});

			_this.$modal.on('hide.bs.modal', function(e) {
		    	$(window).unbind('resize.modal');
			});

			
			_this.$action.click(onClick);

			if(App.isDesktop) {
				_this.$action.tooltip({
					animation : true,
					trigger : 'manual'
				});
				_this.$action.hover(function() {
					_this.$action.tooltip('show');
				}, function() {
					_this.$action.tooltip('hide');
				});
			}

		}

		function onClick(event) {
			event.preventDefault();

			if(App.isDesktop)
				_this.$action.tooltip('hide');

			if(_this.loading)
				return;

			_this.loading = true;
			var def = loadRoute(_this.data('url'), _this.data('url-vars'));
			def.done(function(data) {
				buildModal(data.success);
				showModal();
				_this.loading = false;
			});
		}

		function onMouseEnter() {
			_this.$action.tooltip('show');
		}

		function loadRoute(url, data) {
			var deferred = $.Deferred();
			$.ajax({
				url : url,
				dataType : 'json',
				data : data,
				method: 'post',
				success : deferred.resolve
			});
			return deferred.promise();
		}


		function buildModal(status) {
			_this.$modal.find('.modal-body p').remove();
			_this.$modal.find('.modal-body').prepend('<p>' + status + '</p>');
		}

		function showModal() {
			_this.$modal.modal('show');
		}

		function centerModal() {
		    _this.$modal.each( function(i) {
		        var $clone = $(this).clone().css('display', 'block').appendTo('body');
		        var top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2);
		        top = top > 0 ? top : 0;
		        top = top > 100 ? top-30 : top;
		        $clone.remove();
		        $(this).find('.modal-content').css("margin-top", top);
		    });
		}

		init();
		return this;
	};
	
	BEHAVIOUR.cart = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__cart'))
				return;
			_this.data('__cart',true);

			_this.find('.icon-refresh').click(function() {
				$(this).closest('form').submit();
			});

		}

		init();
		return this;
	};

	BEHAVIOUR.categoryNav = function() {

		// require jScrollPane
		// require TweenMax
		
		var _this = $(this);

		function init() {

			if (_this.data('__categoryNav'))
				return;
			_this.data('__categoryNav',true);


			_this.itemSelector = ".category-nav__item";
			_this.cardSelector = ".category-nav__card";
			_this.labelSelector = ".category-nav__label";
			_this.subItemsSelector = ".category-nav__sub-items";
			_this.iconSelector = ".category-nav__icon span";
			_this.showToggleClassName = "category-nav__item--show";
			_this.stickyToggleClassName = "category-nav--sticky";

			_this.isSticky = false;
			_this.isLoading = false;

			_this.$placeholder = $('<div class="category-nav-placeholder"/>').insertAfter(_this).hide();			
			
			$(document).on('productlist:loaded', function(event, $html) {
				_this.find('.category-nav__items').replaceWith($html.find('.category-nav__items'));
				afterInit();
			});

			$( window ).scroll(function() {
				checkPosition();
			});

			$(window).resize(function() {
				updatePlaceholderHeight();
				checkPosition();
			});

			if(!App.isDesktop) {
				_this.on('touchstart click', function(event) {
					event.stopPropagation();
				});

				$('body').on('touchstart click', function(event) {
					closeMenus();
				});
			}

			afterInit();

		}

		function afterInit() {
			_this.elements = [];

			_this.find(_this.itemSelector).each(function() {
				var $this = $(this);
				if($this.find(_this.subItemsSelector).length>0) {
					initializeItem($this);
				}
			});

			// ajax calls
			_this.find('.category-nav__sub-items a').click(function(event) {
				var $this = $(this),
					href = $this.attr('href'),
					isProductPage = $('.story').length > 0;

				if(isProductPage && href !== undefined) {
					event.preventDefault();
					if(_this.isLoading)
						return;
					_this.isLoading = true;
					$(document).trigger('productlist:load', [href]);
				}

			});

			_this.isLoading = false;
		}

		function attachSticky() {
			if(_this.isSticky)
				return;

			updatePlaceholderHeight();
			_this.isSticky = true;
			_this.$placeholder.show();
			_this.addClass(_this.stickyToggleClassName);

		}

		function removeSticky() {

			if(!_this.isSticky)
				return;

			_this.isSticky = false;
			_this.removeClass(_this.stickyToggleClassName);
			_this.$placeholder.hide();
			
		}

		function checkPosition() {
			var target = _this.isSticky ? _this.$placeholder : _this;
			var top = target.offset().top;
			var windowScroll = $(window).scrollTop();
			if(windowScroll>top) {
				attachSticky();
			} else {
				removeSticky();
			}
		}

		function updatePlaceholderHeight() {
			var height = _this.outerHeight();
			_this.$placeholder.height(height);
		}

		function initializeItem($element) {

			_this.elements.push($element);

			if(App.isDesktop) {
				$element.hover(function() {
					openMenu($element);
				}, function() {
					closeMenu($element);
				});
			}

			if(!App.isDesktop) {
				$element.find(_this.labelSelector).click(function(event) {
					event.preventDefault();
					toggleMenu($(this).closest(_this.itemSelector));
				});
			}


			var $card = $element.find(_this.cardSelector);
			TweenMax.set($card, {scaleY: 0.5});

			var $subItems = $element.find(_this.subItemsSelector);
			TweenMax.set($subItems, {opacity: 0, top: 10});
			
		}

		function openMenu($element) {

			closeMenus();

			if($element.hasClass(_this.showToggleClassName))
				return;

			$element.addClass(_this.showToggleClassName);

			var $icon = $element.find(_this.iconSelector);
			TweenMax.to($icon, 0.2, {rotation: -180, force3D: true});

			var $card = $element.find(_this.cardSelector);
			TweenMax.to($card, 0.25, {scaleY: 1});

			var $subItems = $element.find(_this.subItemsSelector);
			TweenMax.to($subItems, 0.3, {opacity: 1, top: 0, delay: 0.2});
			
			if(App.isDesktop)
				$subItems.jScrollPane();
		}

		function closeMenu($element) {
			
			if(!$element.hasClass(_this.showToggleClassName))
				return;

			$element.removeClass(_this.showToggleClassName);

			var $icon = $element.find(_this.iconSelector);
			var $card = $element.find(_this.cardSelector);
			var $subItems = $element.find(_this.subItemsSelector);

 
			TweenMax.to($icon, 0.2, {rotation: 0, force3D: true});

			TweenMax.to($card, 0.3, {scaleY: 0.5});

			TweenMax.killTweensOf($subItems);
			TweenMax.set($subItems, {opacity: 0, top: 10});
		}

		function toggleMenu($element) {
			if($element.hasClass(_this.showToggleClassName)) {
				 closeMenu($element);
			} else {
				 openMenu($element);
			}
		}

		function closeMenus($except) {
			$.each(_this.elements, function() {
				closeMenu(this);
			});

		}

		init();
		return this;
	};

	BEHAVIOUR.closeOverlayMobile = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__closeOverlayMobile'))
				return;
			_this.data('__closeOverlayMobile',true);
			
			_this.click(function(event) {
				event.preventDefault();

				var $overlay = $('.overlay-mobile.is-open');
				var $overlayHeader = $overlay.find('.overlay-mobile-header');
				var $overlayContainer = $overlay.find('.overlay-mobile__container');
				var $window = $(window);
				var $body = $('body');
				var $bodyWrapper = $('.body-wrapper');

				// hide overlay header and content
				TweenMax.set($overlayHeader, {opacity: 0});
				TweenMax.set($overlayContainer, {opacity: 0});
				TweenMax.set($overlay, {position: 'fixed'});
				TweenMax.set($bodyWrapper, {display: 'block'});
				$body.removeClass('overlay-mobile-is-open');

				// restore overlay content is it's been appended
				if($overlay.data('content')) {
					if($overlay.data('content-prev')) {
						$overlay.data('content-prev').after($overlay.data('content'));
					} else {
						$overlay.data('content-parent').prepend($overlay.data('content'));
					}
				}

				TweenMax.delayedCall(0, function() {
					$window.scrollTop($overlay.data('window-scroll'));
				});

				TweenMax.to($overlay, 0.3, {scale: 0.8, opacity: 0, clearProps: 'scale'});
				TweenMax.delayedCall(0.3, function() {
					$overlay.removeClass('is-open').hide();
				});


			});

		}

		init();
		return this;
	};

	BEHAVIOUR.fbq = function() {

		var _this = $(this);

		function init() {

			if (_this.data('__fbq'))
				return;
			_this.data('__fbq',true);

			var trigger = _this.data('fbq-trigger');
			if(trigger) {
			  _this.on(trigger, track);
			} else {
			  track();
			}
		}

		function track() {
			if(window.fbq)
				window.fbq('track', _this.data('fbq-action'), _this.data('fbq-content'));
		}

		init();
	};

	BEHAVIOUR.filtersButton = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__filtersButton'))
				return;
			_this.data('__filtersButton',true);

			_this.stickyToggleClassName = "filters-button--sticky";

			_this.waypoint({
				handler: function(direction) {
					if(direction=="down") {
						_this.addClass(_this.stickyToggleClassName);
					} else {
						_this.removeClass(_this.stickyToggleClassName);
					}
				},
				offset : function() {
					var h = $('.main-header').outerHeight();
					return h;
				}
			});

			_this.click(function(event) {
				if(_this.data('toggle') != false) {
					event.preventDefault();
					$('#flyout-filters').addClass('flyout--in');
				}
			});

		}

		init();
		return this;
	};

	BEHAVIOUR.filtersNav = function() {

		// require affix
		
		var _this = $(this);

		function init() {

			if (_this.data('__filtersNav'))
				return;
			_this.data('__filtersNav',true);

			// setAffix();

			// $(window).resize(function() {
			// 	_this.affix('checkPosition');
			// });

			$(document).on('productlist:loaded', function(event, $html) {
				_this.html($html.find('.filters-nav').html());
				afterInit();
			});

			$(document).on("productlist:loadstart", function() {
				_this.addClass('is-blocked');
			});

			$(document).on("productlist:loaded", function() {
				_this.removeClass('is-blocked');
			});

			afterInit();

		}

		function setAffix() {
			_this.affix({
				offset : {
					top: function() {
						return ($('.story').offset().top + $('.story').outerHeight(true));
					},
					bottom: function() {
						return ($('.main-footer').outerHeight(true) + 64);
					}
				}
			});
		}

		function afterInit() {

			_this.$productList = $('.product-list');

			adjustMinHeight();

			_this.find('select').each(function() {
				var $this = $(this),
					val = $this.val(),
					text = $this.find('option[value="' + val + '"]').text();
				$this.parent().find('[data-label]').text(text);
			});

			_this.find('a').click(function(event) {
				var href= $(this).attr('href');
				if(href===undefined)
					return;

				if(href!="#") {
					event.preventDefault();
					$(document).trigger("productlist:load", [href]);
				}
			});

			_this.find('select').change(function() {
				var $this = $(this),
					val = $this.val(),
					text = $this.find('option[value="' + val + '"]').text();

				$this.parent().find('[data-label]').text(text);
				$(document).trigger("productlist:load", [val]);
				
			});

			_this.find('.filters-nav__filter-list > li > a').click(function(event) {
				event.preventDefault();
				var $this = $(this);
				var $parent = $this.parent();
				var $content = $parent.find('ul');
				if($parent.hasClass('is-open')) {
					$content.slideUp({
						start: function() {
							$parent.removeClass('is-open');
							$content.css('display', 'block');
						},
						progress : function() {	
							// _this.affix('checkPosition');
							adjustMinHeight();
						}
					});
				} else {
					$content.slideDown({
						start: function() {
							$content.height(0);
							$parent.addClass('is-open');
						},
						progress : function() {
							// _this.affix('checkPosition');
							adjustMinHeight();
						}
					});
				}
			});

			_this.find('.filters-nav__toggle a').click(function(event) {
				event.preventDefault();
				var $content = $('.filters-nav__filter-list--advanced');
				if($content.hasClass('is-visible')) {
					$content.slideUp({
						complete: function() {
							$content.removeClass('is-visible');
						},
						progress : function() {
							// _this.affix('checkPosition');
							adjustMinHeight();
						}
					});
				} else {
					$content.slideDown({
						start: function() {
							$content.height(0);
							$content.addClass('is-visible');
						},
						progress : function() {
							// _this.affix('checkPosition');
							adjustMinHeight();
						}
					});
				}
			});

			// makeFakeSelect();

			// style disabled categories
			_this.find('.filters-nav__filter-list > li').each(function() {
				var $this = $(this),
					isFolder = $this.has('ul'),
					activeCount = $this.find('li').not('.is-disabled').length;
				if(activeCount===0 && isFolder.length)
					$this.addClass('is-disabled');
			});
		}

		function adjustMinHeight() {
			_this.$productList.css('min-height', _this.outerHeight() + 140);
		}

		function makeFakeSelect() {
			
			if(App.isDesktop)
				return;

			_this.find('.filters-nav__select').each(function() {

				var updateDropDown = function() {
					$dropDown.find('.is-active').removeClass('is-active');
					$dropDown.find('li[data-value="' + $select.val() + '"]').addClass('is-active');
				};

				var $el = $(this);

				var $select = $el.find('select');
				var $dropDown = $('<ul class="filters-nav__select__dropdown"></ul>');

				$el.find('option').each(function() {
					var $option = $(this);
					var $dropOption = $('<li data-value="' + $option.attr('value') + '"><span class="icon-flag"></span>' + $option.text() + '</li>');
					$dropOption.appendTo($dropDown);
				});

				$dropDown.appendTo($el);

				$el.find('.filters-nav__select__label').on('click', function(event) {
					event.stopPropagation();
					if(!$dropDown.hasClass('is-open'))
						$dropDown.addClass('is-open');
				});

				$el.on('touchstart', function(event) {
					event.stopPropagation();
				});

				$('body').on('touchstart click', function(event) {
					if($dropDown.hasClass('is-open'))
						$dropDown.removeClass('is-open');
				});

				$dropDown.find('li').click(function(event) {
					event.stopPropagation();
					$dropDown.removeClass('is-open');
					var $li = $(this);
					var value = $li.data('value');
					$select.val(value).change();
					updateDropDown();
				});

				$el.addClass('filters-nav__select--fake');

				updateDropDown();

			});

			
		}

		init();
		return this;
	};

	BEHAVIOUR.filtersActive = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__filtersActive'))
				return;
			_this.data('__filtersActive',true);

			$(document).on('productlist:loaded', function(event, $html) {
				_this.html($html.find('.filters-active').html());
			});

			$(document).on("productlist:loadstart", function() {
				_this.addClass('is-blocked');
			});

			$(document).on("productlist:loaded", function() {
				_this.removeClass('is-blocked');
			});

			_this.on('click', 'a', onClick);

		}

		function onClick(event) {
			var href = $(this).attr('href');
			if(href===undefined)
				return;

			if(href!="#") {
				event.preventDefault();
				$(document).trigger("productlist:load", [href]);
			}
		}

		init();
		return this;
	};	

	BEHAVIOUR.flyoutFilters = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__flyoutFilters'))
				return;
			_this.data('__flyoutFilters',true);

			_this.loading = true;

			initClose();
			initAccordion();
			initSelect();
			initCheckbox();

			alignFilters();
			updateFiltersStatus();

			_this.loading = false;


			$(document).on('productlist:loaded', function() {
				onProductListLoaded();
			});

			$(document).on('productlist:loadstart', function() {
				_this.loading = true;
				blockUi(true);
			});
		}

		function initClose() {
			_this.find('.flyout__title .close').click(function(event) {
				event.preventDefault();
				_this.removeClass('flyout--in');
			});
		}

		function initAccordion() {
			_this.find('.flyout__filters__accordion-toggle').click(function(event) {
				event.preventDefault();
				var $this = $(this);
				var $parent = $this.parent();
				var $content = $this.next();
				if($parent.hasClass('is-open')) {
					$content.slideUp();
				} else {
					$content.slideDown();
				}
				$parent.toggleClass('is-open');
			});
		}

		function initSelect() {

			_this.find('select').change(function() {

				// console.log('select change');
				
				var $this = $(this),
					val = $this.val(),
					$selected = $this.find('option[value="'+val+'"]'),
					text = $selected.text(),
					dataUrl = $this.data('url') === true;
					
				$this.parent().find('[data-label]').text(text);

				if(!_this.loading) {
					if(dataUrl) {	
						$(document).trigger('productlist:load', [val]);
					} else {
						$(document).trigger('productlist:load', [$selected.data('href')]);
					}
				}
				
			});
		}

		function initCheckbox() {
			_this.find('.flyout__filters__checkbox').click(function() {
				var $this = $(this),
					className = 'is-selected',
					isDisabled = $this.data('is-disabled');
				if(isDisabled)
					return;
				$this.toggleClass(className);
				$this.data('is-active', $this.hasClass(className));
				$(document).trigger('productlist:load', [$this.data('href')]);
			});
			
		}

		function alignFilters() {
			
			// reset all states
			_this.find('option, .flyout__filters__checkbox').data('is-active', false).data('is-disabled', false);
			_this.find('.is-selected').removeClass('is-selected');
			_this.find('.is-disabled').removeClass('is-disabled');
			_this.find('[data-total]').text('');

			// console.log('FILTER: align .filters-nav select[data-url="true"]');

			// console.log('alignFilters');
			// console.log('checking .filters-nav select[data-url="true"]');


			// this is actually the "order by" select only
			$('.filters-nav select[data-url="true"]').each(function() {
				var $this = $(this),
					val = $this.val(),
					name = $this.attr('name'),
					thisHtml = $this.html(),
					$target = _this.find('select[data-url="true"][name="'+name+'"]');

				$target.html(thisHtml);
				$target.val(val).change();
				
			});

			$('.category-nav li[fn], .filters-nav li[fn]').each(function() {
				var $this = $(this);
				var name = $this.attr('fn');
				var value = $this.attr('fv');
				var isActive = $this.attr('fa') === "1";
				var isDisabled = $this.hasClass('is-disabled');
				var href = $this.find('a').attr('href');

				// console.log('analyzing ' + $(this).find('a').text() + ' active: ' + isActive + ' disabled: ' + isDisabled);

				var $elements = _this.find('[fn="'+name+'"][fv="'+value+'"]');
				// console.log('finding elements ' + $(this).find('a').text() + ' length: ' + $elements.length);
				$elements.each(function() {
					var $this = $(this);
					$this.data('href', href);
					$this.data('is-active', isActive);
					$this.data('is-disabled', isDisabled);
				});

			});
			
			_this.find('[data-total-result]').text($('.filters-nav__total span').text());
		}

		function updateFiltersStatus() {
			
			// reset all selects except the "data-url" ones
			_this.find('select').each(function() {
				var $this = $(this);
				if($this.data('url')!==true)
					$this.val("").change();
			});

			_this.find('option').each(function() {
				var $this = $(this);
				// console.log('updating option: ' + $this.text() + ' active: ' + $this.data('is-active') + ' disabled: ' + $this.data('is-disabled'));
				if($this.data('is-active')===true) {
					var value = $this.attr('value');
					$this.parent().val(value).change();
				}
				if($this.data('is-disabled')===true) {
					$this.attr('disabled', 'disabled');
				} else {
					$this.removeAttr("disabled");
				}	
			});

			// update checkboxes
			_this.find('.flyout__filters__checkbox').each(function() {
				var $this = $(this);
				if($this.data('is-active')===true)
					$this.addClass('is-selected');
				if($this.data('is-disabled')===true)
					$this.addClass('is-disabled');
			});

			// update totals on accordions and disabled style
			_this.find('.flyout__filters__accordion').each(function() {
				var $this = $(this);
				var $content = $this.find('.flyout__filters__accordion-content');
				var totalCount = $content.find(' > *').length;
				var countSelected = $content.find('.is-selected').length;
				var countDisabled = $content.find('.is-disabled').length;
				var htmlCount = '';

				if(countSelected)
					htmlCount = "( " + countSelected + " )";
				if(totalCount == countDisabled) {
					$this.addClass('is-disabled');
				} else {
					$this.removeClass('is-disabled');
				}
				$this.find('[data-total]').text(htmlCount);
			});
		}

		function onProductListLoaded() {
			alignFilters();
			updateFiltersStatus();
			blockUi(false);
			_this.loading= false;
		}

		function blockUi(action) {
			var $filters = _this.find('.flyout__filters'),
				className = "is-blocked";
			if(action) {
				$filters.addClass(className);
			} else {
				$filters.removeClass(className);
			}
		}


		init();
		return this;
	};

	BEHAVIOUR.footerToggle = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__footerToggle'))
				return;
			_this.data('__footerToggle',true);

			_this.click(function(event) {
				event.preventDefault();
				var $this = $(this);
				var $content = $(this).next();
				if($this.hasClass('is-open')) {
					$content.slideUp();
				} else {
					$content.slideDown();
				}
				$this.toggleClass('is-open');
			});

		}

		function toggle(selector, className) {
			// console.log('toggling class:');
			// console.log(selector);
			// console.log(className);
			$(selector).toggleClass(className);
		}

		init();
		return this;
	};

	BEHAVIOUR.giftCard = function() {
		
		var _this = $(this);
		var $images = _this.find('[data-image-full]');
		var $thumbs = _this.find('[data-image-thumb]');

		function init() {

			if (_this.data('__giftCard'))
				return;
			_this.data('__giftCard',true);

			$thumbs.on('click', onThumbClick);

			var optionIndex = 0;

			var $checkedInput = $thumbs.find('input:checked').first();
			if($checkedInput.length > 0) {
				optionIndex = $checkedInput.parent().index();
			}
			
			selectTheme(optionIndex);
		}

		function onThumbClick(event) {
			event.preventDefault();
			selectTheme($(this).index());
		}

		function selectTheme(index) {
			$images
				.removeClass('is-active')
				.eq(index).addClass('is-active');
			$thumbs
				.removeClass('is-active')
				.eq(index)
				.addClass('is-active')
				.find('input').prop("checked", true);
		}

		init();
		return this;
	};

	BEHAVIOUR.homeSlider = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__homeSlider'))
				return;
			_this.data('__homeSlider',true);

			var $slides = _this.find('.swiper-slide');
			var totalSlide = $slides.length;

			if(totalSlide<2)
				return;

			$slides.each(function() {
				_this.find('.home-slider__pagination').append('<span class="swiper-pagination-bullet" />');
			});

			var swiper = new Swiper (_this.find('.swiper-container'), {
				slidesPerView: 1,
				loop: true,
				speed: 1200,
				autoplay: 3000,
				autoHeight: true,
				spaceBetween: 0,
				nextButton: _this.find('.slider-nav__next'),
				prevButton: _this.find('.slider-nav__prev'),
				paginationType: 'custom',
				pagination: _this.find('.slider-nav__pages'),
				runCallbacksOnInit: false,
    			paginationCustomRender: function(swiper, current, total) {
    				var activeIndex = $(swiper.slides[swiper.activeIndex]).data('swiper-slide-index');
    				_this.find('.home-slider__pagination span')
    					.eq(activeIndex)
    					.addClass('swiper-pagination-bullet-active')
    					.siblings()
    					.removeClass('swiper-pagination-bullet-active');
    				return '<span>'+current + '</span> / <span>' + total + '</span>';
    			// },
    			// onSlideNextStart: function(swiper) {
    			// 	var $slide = swiper.slides.eq(swiper.activeIndex);
    			// 	swiper.slides.css('z-index', 1);
    			// 	$slide.css('z-index', 100);
    			// 	TweenMax.to($slide, 0.2, {x:"-100px", ease: Power2.easeOut, onComplete: function() {
    			// 		TweenMax.to($slide, 0.5, {x:"0", ease: Power2.easeOut});
    			// 	}});
    			// },
    			// onSlidePrevStart: function(swiper) {
    			// 	var $slide = swiper.slides.eq(swiper.activeIndex);
    			// 	swiper.slides.css('z-index', 1);
    			// 	$slide.css('z-index', 100);
    			// 	TweenMax.to($slide, 0.2, {x:"100px",  ease: Power2.easeOut, onComplete: function() {
    			// 		TweenMax.to($slide, 0.5, {x:"0", ease: Power2.easeOut});
    			// 	}});
    			}
			});

			_this.find('.swiper-container').hover(function() {
				swiper.stopAutoplay();
			}, function() {
				swiper.startAutoplay();
			});

			_this.find('.home-slider__nav').removeClass('hidden');

		}

		init();
		return this;
	};

	BEHAVIOUR.instagram = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__instagram'))
				return;
			_this.data('__instagram',true);

			$.ajax({
				url : _this.data('url'),
				processData: false,
				success: function(r) {
					_this.html(r);
				}
			});
		}

		init();
		return this;
	};

	BEHAVIOUR.languages = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__languages'))
				return;
			_this.data('__languages',true);

			_this.find('a').click(function(event) {
				event.preventDefault();
				var name = $(this).attr('name');
				_this.find('input[name="code"]').val(name);
				_this.submit();
			});
		}

		init();
		return this;
	};
	
	BEHAVIOUR.loadSvg = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__loadSvg'))
				return;
			_this.data('__loadSvg',true);

			var url = _this.data('svg');

			if(checkLoaded(url))
				return;

			registerLoad(url);

			$.ajax({
				url : url,
				dataType: "text",
				success: function(r) {
					$('body').prepend('<div style="display: none">' + r + '</div>');
				}
			});
		}

		function checkLoaded(url) {
			return (App.svg != undefined && App.svg[url]);
		}

		function registerLoad(url) {
			if(App.svg == undefined)
				App.svg = {};
			App.svg[url] = true;
		}

		init();
		return this;
	};

	BEHAVIOUR.openOverlayMobile = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__openOverlayMobile'))
				return;
			_this.data('__openOverlayMobile',true);

			_this.click(function(event) {
				event.preventDefault();

				var $overlay = $(_this.data('overlay-mobile'));
				var $overlayHeader = $overlay.find('.overlay-mobile-header');
				var $overlayContainer = $overlay.find('.overlay-mobile__container');
				var $body = $('body');
				var $bodyWrapper = $('.body-wrapper');
				var $window = $(window);

				// if a content is specified, append the content to the overlay container
				// used for filters nav
				var overlayContent = _this.data('overlay-mobile-content');
				if(overlayContent) {

					var $overlayContent = $(overlayContent);

					// save content reference and position to restore it later
					$overlay.data('content', $overlayContent);
					$overlay.data('content-parent', $overlayContent.parent());
					$overlay.data('content-prev', $overlayContent.prev());

					// append to overlay container
					$(overlayContent).appendTo($overlayContainer);
				}

				if(!$overlay.parent().is('body')) {
					$overlay.appendTo('body');
				}

				$overlay.addClass('is-open');

				// save window scroll top to restore it later
				$overlay.data('window-scroll', $window.scrollTop());
				
				// init overlay and body
				TweenMax.set($overlayHeader, {y: -20, opacity:0});
				TweenMax.set($overlayContainer, {y: -20, opacity:0});
				TweenMax.set($overlay, {display: 'block', position: 'fixed', opacity:0, scale: 0.8});
				TweenMax.set($body, {overflow: 'hidden'});
				
				// show overlay
				TweenMax.to($overlay, 0.3, {scale: 1, opacity: 1, clearProps: 'scale'});

				// hide body wrapper and set overlay to static
				TweenMax.set($overlay, {position: 'static', delay: 0.3});
				TweenMax.set($body, {overflow: 'auto', delay: 0.3});
				TweenMax.set($bodyWrapper, {display: 'none', delay: 0.3});
				TweenMax.delayedCall(0.3, function() {
					$body.addClass('overlay-mobile-is-open');
					$window.scrollTop(0);
				});

				// show overlay header and content
				TweenMax.to($overlayHeader, 0.3, {y: 0, opacity: 1, delay: 0.3});
				TweenMax.to($overlayContainer, 0.6, {y: 0, opacity: 1, delay: 0.3});


			});

		}

		init();
		return this;
	};

	BEHAVIOUR.pageAlert = function() {

		// require js.cookie

		var _this = $(this);

		function init() {

			if (_this.data('__pageAlert'))
				return;
			_this.data('__pageAlert',true);

			var cookieName = _this.data('cookie-name');
			
			if(Cookies.get(cookieName))
				return;

			_this.removeClass('hidden');

			_this.find('a, .close').click(function() {
				_this.remove();
				Cookies.set(cookieName, '1', { expires: 30*3 });
			});

		}

		init();
		return this;
	};

	BEHAVIOUR.producerAlphabet = function() {

		// require TweenMax
		// require ScrollToPlugin

		var _this = $(this);

		function init() {

			if (_this.data('__producerAlphabet'))
				return;
			_this.data('__producerAlphabet',true);

			_this.find('a').click(function(event) {
				event.preventDefault();
				var $this = $(this);

				if($this.hasClass('is-disabled'))
					return;

				var $href = $($this.attr('href'));
				if($href.length===0)
					return;

				var top = $href.offset().top;

				var $categoryNav = $('.category-nav');
				var $header =  $('.main-header');
				var offset;
				if($categoryNav.css('display')=="none") {
					offset = $header.outerHeight();
				} else {
					offset = $categoryNav.outerHeight();
				}
				// offset = 0;
				// console.log(offset);


				TweenMax.to(window, 0.6, {scrollTo:{y:top, offsetY: offset, autoKill:false}});

			});

			return;

		}

		init();
		return this;
	};

	BEHAVIOUR.productAddButton = function() {

		var _this = $(this);

		function init() {

			if (_this.data('__productAddButton'))
				return;
			_this.data('__productAddButton',true);

			_this.loading = false;

			_this.$modal = $('#modal-attention');

			_this.$modal.on('show.bs.modal', function(e) {
				centerModal();
				$(window).bind('resize.modal', function() {
					centerModal();
				});
			});

			_this.$modal.on('hide.bs.modal', function(e) {
		    	$(window).unbind('resize.modal');
			});


			_this.find('> button').click(function(event) {
				event.preventDefault();
				// showSucces();
				makeCall();
			});

			 _this.find('.product-add-button__actions button').click(function() {
			 	closeSuccess();
			 });

		}

		function makeCall() {
			if(_this.loading)
				return;

			_this.loading = true;

			var url = _this.data('url');

			var data = {};
			data.product_id = _this.data('product-id');

			// find quantity input
			$parent = _this.closest('.product-preview').length ? _this.closest('.product-preview') : _this.closest('.product-detail');
			data.quantity = $parent.find('input[name="product-quantity"]').val();

			$.ajax({
				url : url,
				data: data,
				method: 'post',
				dataType: 'json',
				success: function(r) {
					if('error' in r) {
						_this.loading = false;
						showError(r.error);
					} else {
						updateCart(r.total);
						showSuccess();
					}
				}
			});

			$(window).resize(function() {
				setIconModifier();
			});
		}

		function updateCart(total) {
			var $cartNumber = $('.main-header__cart-total');
			var $header = $('.main-header');
			var className = 'cart-is-full';
			$cartNumber.text(total);
			if(parseInt(total)>0) {
				$header.addClass(className);
				TweenMax.set($cartNumber, {y: -10, opacity: 0});
				TweenMax.to($cartNumber, 0.3, {y: 0, opacity: 1});
			} else {
				TweenMax.set($cartNumber, {opacity: 0});
				$header.removeClass(className);
			}
		}

		function showSuccess() {

			setIconModifier();

			var $success = _this.find('.product-add-button__success');
			var $actions = _this.find('.product-add-button__actions');
			var $text = _this.find('.product-add-button__text');
			var $button = _this.find('> button');
			var $siblings = _this.siblings();
			var buttonWidth = $button.outerWidth();
			var boxWidth = _this.width();


			TweenMax.set($success, {left: boxWidth - buttonWidth, opacity: 1, display: 'block'});
			TweenMax.set($text, {opacity: 0});
			TweenMax.to($success, 0.2, {opacity : 1});
			TweenMax.to($siblings, 0.2, {opacity : 0, onComplete: function() {
				TweenMax.set($siblings, {display: 'none'});
			}});
			TweenMax.to($success, 0.4, {left : 0, ease: Power2.easeOut});
			TweenMax.to($text, 0.3, {opacity : 1, delay: 0.4});

			var delay = 2;

			TweenMax.to($text, 0.3, {opacity : 0, delay: delay});
			TweenMax.set($actions, {display: 'block', opacity: 0, delay: delay});
			TweenMax.to($actions, 0.3, {opacity : 1, delay: delay});
		}

		function closeSuccess() {

			var $success = _this.find('.product-add-button__success');
			var $actions = _this.find('.product-add-button__actions');
			var $siblings = _this.siblings();
			var $button = _this.find('> button');
			var buttonWidth = $button.outerWidth();
			var boxWidth = _this.width();

			TweenMax.to($actions, 0.3, {opacity : 0, onComplete: function() {
				TweenMax.set($actions, {display: 'none'});
			}});

			TweenMax.to($success, 0.4, {left : boxWidth - buttonWidth, ease: Power2.easeOut, delay: 0.3});
			TweenMax.set($siblings, {display : 'block', delay: 0.3});
			TweenMax.to($siblings, 0.2, {opacity : 1, delay: 0.3});
			TweenMax.to($success, 0.2, {opacity : 0, delay: 0.6, onComplete: function() {
				TweenMax.set($success, {display: 'none'});
				_this.loading = false;
			}});
		}

		function setIconModifier() {
			if(_this.outerWidth() > 310) {
				_this.removeClass('is-small');
			} else {
				_this.addClass('is-small');
			}
		}

		function showError(errors) {
			
			$modalContent = _this.$modal.find('.modal-body-content').empty();

			$.each(errors, function(i) {
				$modalContent.append('<p>' + this + '</p>');
			});
			_this.$modal.modal();

		}

		function centerModal() {
		    _this.$modal.each( function(i) {
		        var $clone = $(this).clone().css('display', 'block').appendTo('body');
		        var top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2);
		        top = top > 0 ? top : 0;
		        top = top > 100 ? top-30 : top;
		        $clone.remove();
		        $(this).find('.modal-content').css("margin-top", top);
		    });
		}

		init();
		return this;
	};

	BEHAVIOUR.productDetail = function() {

		var _this = $(this);

		function init() {

			if (_this.data('__productDetail'))
				return;
			_this.data('__productDetail',true);

			var $slides = _this.find('.swiper-slide');
			var totalSlide = $slides.length;
			
			if(totalSlide>1) {

				$slides.each(function() {
					_this.find('.product-detail__pagination').append('<span class="swiper-pagination-bullet" />');
				});

				var s = new Swiper (_this.find('.swiper-container'), {
					// slidesPerView: 1,
					loop: true,
					speed: 900,
					nextButton: _this.find('.slider-nav__next'),
					prevButton: _this.find('.slider-nav__prev'),
					paginationType: 'custom',
					pagination: _this.find('.slider-nav__pages'),
	    			paginationCustomRender: function(swiper, current, total) {
	    				var activeIndex = $(swiper.slides[swiper.activeIndex]).data('swiper-slide-index');
	    				_this.find('.product-detail__pagination span')
	    					.eq(activeIndex)
	    					.addClass('swiper-pagination-bullet-active')
	    					.siblings()
	    					.removeClass('swiper-pagination-bullet-active');
	    				return '<span>'+current + '</span> / <span>' + total + '</span>';
	    			}
				});
				
			} else {
				_this.find('.product-detail__nav').hide();
			}


		}

		init();
		return this;
	};

	BEHAVIOUR.productListAjax = function() {

		// require TweenMax

		var _this = $(this);

		function init() {

			if (_this.data('__productListAjax'))
				return;
			_this.data('__productListAjax',true);

			if($('.product-list').length === 0)
				return;

			_this.useAjax = $('body').data('ajax') != "disabled";
			_this.historyAvailable = window.history && history.pushState;
			_this.jqxhr = undefined;

			if(_this.useAjax && _this.historyAvailable)
				history.pushState({context: "product-list", url: document.location.href}, 'title', document.location.href);

			// $(document).on('productlist:loadstart', function(event) {

			// });

			$(document).on('productlist:load', function(event, url) {
				if(_this.historyAvailable && _this.useAjax) {
					history.pushState({context: "product-list", url: url}, 'title', url);
					load(url);
				} else {
					document.location.href = url;
				}
			});

			$(window).on('popstate', function(e) {
				// console.log('popstate');
				// console.log(e.originalEvent.state);
				if(e.originalEvent.state && e.originalEvent.state.context == "product-list") {
					load(e.originalEvent.state.url);
				}
			});

		}

		function load(url) {

			$(document).trigger('productlist:loadstart');

			if(_this.jqxhr) {
				_this.jqxhr.abort();
				_this.jqxhr = undefined;
			}

			$('.product-preview-grid').addClass('is-blocked');

			_this.jqxhr = $.ajax({
				url: url,
				dataType: 'html',
				success: function(r) {
					scrollPage();
					updateProductList(r);
					if(window.ga) {
						window.ga('set', 'page', url);
						window.ga('send', 'pageview');
					}
				}
			});
		}

		function updateProductList(r) {
			var $html = $('<div></div>').append(r);

			$('.product-list__products').replaceWith($html.find('.product-list__products')).GUI();

			$(document).trigger('productlist:loaded', [$html]);
		}

		function scrollPage() {
			var y;
			if(isMobilePage()) {
				y = 0;
				TweenMax.to(window, 0.6, {scrollTo:{y:0}});
			} else {
				var $element = $('.story');
				y = $element.offset().top + $element.outerHeight(true) + 2;
				if(y < $(window).scrollTop())
					TweenMax.to(window, 0.6, {scrollTo:{y:y}});
			}
		}

		function isMobilePage() {
			return $('.filters-block .filters-button').css('display') == "block";
		}

		init();
		return this;
	};
	GLOBAL_BEHAVIOURS.push("productListAjax");

	BEHAVIOUR.productPreview = function() {

		var _this = $(this);

		function init() {

			if (_this.data('__productPreview'))
				return;
			_this.data('__productPreview',true);

			_this.className = 'product-preview';
			_this.$flipToggle = _this.find("footer .icon-cart-add");
			_this.$close = _this.find('.close');
			_this.isFlipped = false;
			_this.$quantity = _this.find('.product-preview__form__quantity');
			_this.quantity = 1;

			if(App.isDesktop) {
				_this.hover(function() {
					_this.toggleClass('hover');
				}, function() {
					_this.toggleClass('hover');
				});
			}

			_this.click(function(event) {
				event.stopPropagation();
			});

			_this.$flipToggle.click(flipOn);
			_this.$close.click(flipOff);

			$('body').bind('product-preview.flip',function(event, element) {
				if(element!=_this[0]) {
					_this.removeClass('flip');
				}
			});

			$('body').bind('click touchstart', function(event) {
				if(!_this.isFlipped)
					return;
				var $flipped = $(event.target).closest('.product-preview.flip');
				if($flipped[0] != _this[0])
					flipOff();
			});

			_this.find('.product-preview__form__less').click(reduceQuantity);
			_this.find('.product-preview__form__more').click(increaseQuantity);

		}

		function flipOn() {
			setQuantity(1);
			_this.addClass('flip');
			_this.isFlipped = true;
			$('body').trigger('product-preview.flip', _this);
		}

		function flipOff() {
			_this.removeClass('flip');
		}

		function reduceQuantity() {
			var q = getQuantity();
			q = (q>1) ? q-1 : q;
			setQuantity(q);
		}

		function increaseQuantity() {
			var q = getQuantity();
			q++;
			setQuantity(q);
		}

		function getQuantity() {
			var q = parseInt(_this.$quantity.text());
			if(isNaN(q) || q<1)
				q = 1;
			return q;
		}

		function setQuantity(q) {
			_this.quantity = q;
			_this.$quantity.text(q);
		}

		init();
		return this;
	};

	BEHAVIOUR.productPreviewSlider = function() {
		

		var _this = $(this);

		function init() {

			if (_this.data('__productPreviewSlider'))
				return;
			_this.data('__productPreviewSlider',true);

			var isCollection = _this.hasClass('product-preview-slider--collection');

			var breakpoints = {};

			if(isCollection) {
				breakpoints[App.breakpoints.md-1] = {
					slidesPerGroup: 1,
					slidesPerView: 1
				};
				breakpoints[App.breakpoints.lg-1] = {
					slidesPerGroup: 2,
					slidesPerView: 2
				};
			} else {
				breakpoints[App.breakpoints.sm-1] = {
					slidesPerGroup: 1,
					slidesPerView: 1
				};
				breakpoints[App.breakpoints.md-1] = {
					slidesPerGroup: 2,
					slidesPerView: 2
				};
				breakpoints[App.breakpoints.md2-1] = {
					slidesPerGroup: 3,
					slidesPerView: 3
				};
				breakpoints[App.breakpoints.xl-1] = {
					slidesPerGroup: 4,
					slidesPerView: 4
				};
			}

			var $slides = _this.find('.swiper-slide');
			var totalSlide = $slides.length;
			$slides.each(function() {
				_this.find('.swiper-pagination').append('<span class="swiper-pagination-bullet" />');
			});

			var $nav = _this.find('.product-preview-slider__nav');


			var swiper = new Swiper (_this.find('.swiper-container'), {
				speed: 900,
				roundLengths: true,
				slidesPerGroup: isCollection ? 3 : 5,
				slidesPerView: isCollection ? 3 : 5,
				spaceBetween: App.gridGutterWidth,
				nextButton: _this.find('.icon-long-arrow-right'),
				prevButton: _this.find('.icon-long-arrow-left'),
				paginationType: 'custom',
				pagination: _this.find('.product-preview-slider__pages'),
				paginationCustomRender: function(swiper, current, total) {
					activeIndex = swiper.activeIndex;
					_this.find('.swiper-pagination span')
						.eq(activeIndex)
						.addClass('swiper-pagination-bullet-active')
						.siblings()
						.removeClass('swiper-pagination-bullet-active');
					var html = '<span>'+current + '</span> / <span>' + total + '</span>';
					if(total==1) {
						$nav.addClass('hidden');
					} else {
						$nav.removeClass('hidden');
					}
					return html;
				},
				breakpoints: breakpoints,
				onInit: function(swiper) {
					$(swiper.container).addClass('is-ready');
				}
			});
		}

		function clearSelection() {
		    if(document.selection && document.selection.empty) {
		        document.selection.empty();
		    } else if(window.getSelection) {
		        var sel = window.getSelection();
		        sel.removeAllRanges();
		    }
		}

		init();
		return this;
	};

	BEHAVIOUR.productQuantity = function() {

		var _this = $(this);

		function init() {

			if (_this.data('__productQuantity'))
				return;
			_this.data('__productQuantity',true);

			_this.$quantityLabel = _this.find('.product-quantity__quantity');
			_this.$quantityInput = _this.find('input[name="product-quantity"]');

			_this.maximumQuantity = (_this.data('max-items') !== undefined) ? _this.data('max-items') : 99;

			_this.find('.product-quantity__less').click(function() {
				$(this).blur();
				reduceQuantity();
			});
			_this.find('.product-quantity__more').click(function() {
				$(this).blur();
				increaseQuantity();
			});

			setUIStatus();

		}

		function reduceQuantity() {
			var q = getQuantity();
			q = (q>1) ? q-1 : q;
			setQuantity(q);
		}

		function increaseQuantity() {
			var q = getQuantity();
			q = ((q + 1) > _this.maximumQuantity) ? _this.maximumQuantity : q+1;
			setQuantity(q);
		}

		function getQuantity() {
			var q = parseInt(_this.$quantityLabel.text());
			if(isNaN(q) || q<1)
				q = 1;
			return q;
		}

		function setQuantity(q) {
			_this.$quantityLabel.text(q);
			_this.$quantityInput.val(q);
			updateFbq(q);
			setUIStatus();
		}

		function setUIStatus() {
			_this.removeClass('is-minimum').removeClass('is-maximum');
			var q = getQuantity();
			if(q == 1)
				_this.addClass('is-minimum');
			if(q == _this.maximumQuantity)
				_this.addClass('is-maximum');
		}

		function updateFbq(q) {
			var $addToCart = _this.closest('.product-preview, .product-detail').find('[data-fbq-action="AddToCart"]');
			if($addToCart.length > 0) {
				var data = $addToCart.data('fbq-content');
				try	{
					data.contents[0].quantity = q;
					$addToCart.attr('data-fbq-content', JSON.stringify(data));
				}
				catch(err) {}
			}
		}

		init();
		return this;
	};

	BEHAVIOUR.productTabs = function() {

		var _this = $(this);

		function init() {

			// _this.isMobile = App.isMobile();

			if (_this.data('__productTabs'))
				return;
			_this.data('__productTabs',true);

			buildSlider();

			_this.find('dt').click(function(event) {
				clickMobile($(this));
			});
			//init mobile content
			_this.find('dd.is-active').slideDown();

		}

		function clickMobile($dt) {
			var className = 'is-active';
			var $content = $dt.next();
			
			if($dt.hasClass(className)) {
				$content.slideUp(function() {
					$content.removeClass(className);
				});
			} else {
				$content.slideDown(function() {
					$content.addClass(className);
				});
			}
			$dt.toggleClass(className);
		}

		function buildSlider() {
			var $container = $('<div class="product-tabs__slider" />').appendTo(_this.find('.container-fluid'));

			var $swiperContainer = $('<div class="swiper-container" />');
			var $swiperWrapper = $('<div class="swiper-wrapper" />').appendTo($swiperContainer);
			_this.find('dd').each(function() {
				var $slide = $('<div class="swiper-slide" />').append($(this).html()).appendTo($swiperWrapper);
			});

			$container.append($swiperContainer);
			var swiper = new Swiper ($swiperContainer, {
				slidesPerView: 1,
				loop: false,
				speed: 900,
				autoHeight: true,
				onSlideChangeStart: function() {
					setActiveNav(swiper.activeIndex);
				}
			});

			
			var $tabNav = $('<div class="product-tabs__nav" />');
			_this.find('dt').each(function(i) {
				var $navItem = $('<span class="border-underline">' + $(this).text() + '</span>').data('index',i).appendTo($tabNav);
				$navItem.click(function() {
					swiper.slideTo($(this).data('index'));
				});
			});

			$container.prepend($tabNav);
			setActiveNav(0);
		}

		function setActiveNav(index) {
			var className = 'border-underline--visible';
			_this.find('.product-tabs__nav .' + className).removeClass(className);
			_this.find('.product-tabs__nav span').eq(index).addClass(className);
		}

		init();
		return this;
	};

	BEHAVIOUR.productFooter = function() {

		var _this = $(this);

		function init() {

			if (_this.data('__productFooter'))
				return;
			_this.data('__productFooter',true);

			if(!$('.product-detail__header .product-add').length)
				return;

			_this.scrollReference = $('.product-detail__header .product-add');
			_this.$window = $(window);

			new Waypoint({
			  element: $('.product-detail__header .product-add'),
			  handler: function(direction) {
			  	if(direction=="down") {
			  		_this.addClass('is-visible');
			  	} else {
			  		_this.removeClass('is-visible');
			  	}
			  },
			  offset : -60
			});

			new Waypoint({
			  element: $('.main-footer'),
			  handler: function(direction) {
			  	if(direction=="down") {
			  		_this.removeClass('is-visible');
			  	} else {
			  		_this.addClass('is-visible');
			  	}
			  },
			  offset : "90%"
			});

			

		}

		init();
		return this;
	};

	BEHAVIOUR.reminder = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__reminder'))
				return;
			_this.data('__reminder',true);

			_this.loading = false;

			_this.$form = _this.find('form');

			_this.$form.on('submit', onSubmit);

			_this.$modal = _this;
			_this.$modal.on('show.bs.modal', function(e) {
		    	resetModal();
			});

		}

		function onSubmit(event) {
			event.preventDefault();

			if(_this.loading)
				return;

			_this.loading = true;
			var def = loadRoute(_this.$form.data('url'), _this.$form.serialize());
			def.done(function(data) {
				if(data.message)
					showMessage(data.message);
				_this.loading = false;
			});
		}

		function resetModal() {
			_this.find('[data-form]').removeClass('hidden');
			_this.find('[data-response]').addClass('hidden');
		}

		function showMessage(message) {
			_this.find('[data-message]').text(message);
			_this.find('[data-response]').removeClass('hidden');
			_this.find('[data-form]').addClass('hidden');
		}

		function loadRoute(url, data) {
			var deferred = $.Deferred();
			$.ajax({
				url : url,
				dataType : 'json',
				data : data,
				method: 'post',
				success : deferred.resolve
			});
			return deferred.promise();
		}

		init();
		return this;
	};

	BEHAVIOUR.newCustomerForm = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__newCustomForm'))
				return;
			_this.data('__newCustomForm',true);

			var url = _this.data('url');

			_this.on('submit', function(event) {

				_this.find('.alert').remove();

				event.stopPropagation();
				event.preventDefault();

				$.ajax({
					url: url,
					data: _this.serialize(),
					method: 'get',
					dataType: 'json',
					success: function(r) {
						if(r.success) {
							_this.find('.cta').remove();
							_this.find('.form-content').html('<p class="response">' + r.message + '</p><p class="coupon strong">' + r.coupon + '</p>');
							
							var $succesContent = _this.find('[data-form-success-content]');
							var successTrackPage = _this.data('form-success-track-page');

							if($succesContent.length)
								$succesContent.show();

							if(successTrackPage && window.ga) {
								window.ga('set', 'page', successTrackPage);
								window.ga('send', 'pageview');
							}

							if(window.fbq) {
								window.fbq('track', "Lead", {content_name: "newsletter"});
							}

						} else {
							_this.find('.form-content').prepend('<div class="alert alert-danger">' + r.message + '</div>');
						}
					}
				});

			});

		}



		init();
		return this;
	};

	BEHAVIOUR.newsletterForm = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__newsletterForm'))
				return;
			_this.data('__newsletterForm',true);

			var language = $('html').attr('lang') ?  $('html').attr('lang') : 'it';
			var url = _this.data('url');

			_this.find('input[name="EMAIL"]').attr('type', 'email').attr('required', 'required');
			_this.find('input[name="privacy"]').attr('required', 'required');
			_this.find('button').attr('type', 'submit');

			_this.ajaxChimp({
			  url: url,
			  language: language,
			  callback : callbackFunction
			});

			_this.removeAttr('novalidate');

		}

		function callbackFunction(r) {
			_this.find('.alert').remove();
			if(r.result=="success") {
				_this.find('.form-content').html('<p class="reply">' + r.msg + '</p>');

				var $succesContent = _this.find('[data-form-success-content]');
				var successTrackPage = _this.data('form-success-track-page');

				if($succesContent.length)
					$succesContent.show();

				if(successTrackPage && window.ga) {
					window.ga('set', 'page', successTrackPage);
					window.ga('send', 'pageview');
				}

				if(window.fbq) {
					window.fbq('track', "Lead", {content_name: "newsletter"});
				}
			} else {
				_this.find('.form-content').prepend('<div class="alert alert-'+ r.result + '">' + r.msg + '</div>');
			}
		}


		init();
		return this;
	};

	BEHAVIOUR.mainHeader = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__mainHeader'))
				return;
			_this.data('__mainHeader',true);

			_this.$window = $(window);
			_this.$body = $('body');
			_this.isMobileHeader = isMobileHeader();
			_this.menuTimeout = 0;

			_this.$window.resize(onWindowResize);

			_this.$window.scroll(onWindowScroll);


			_this.find('.main-header__main-nav > ul > li > a').click(function(event) {
				var $li = $(this).parent();
				if($li.find('.main-header__menu').length>0) {
					event.preventDefault();
					if(!$li.hasClass('active')) {
						showMenu($li);
					} else {
						hideMenu($li);
					}
				}
			});

			_this.find('.main-header__main-nav > ul > li > a, .main-header__main-nav > ul > li > div').on('touchstart click', function(event) {
				event.stopPropagation();
			});

			$('body').on('touchstart click', function(event) {
				hideMenus();
			});

			$(document).on('menu:mini-cart-open', function() {
				hideMenus();
			});


		}

		function isMobileHeader() {
			return _this.find('.main-header__main-nav').css('display') == "none";
		}

		function onWindowResize() {
			_this.isMobileHeader = isMobileHeader();
		}

		function onWindowScroll() {
			if(_this.$body.hasClass('overlay-mobile-is-open'))
				return;
			var scrollTop = _this.$window.scrollTop();
			if(scrollTop>0) {
				_this.addClass('is-scrolled');
			} else {
				_this.removeClass('is-scrolled');
			}
			
		}

		function showMenu($el) {

			if($el.hasClass('active'))
				return;
			
			clearInterval(_this.menuTimeout);

			$(document).trigger('menu:main-nav-open');

			// check if a different menu is still open
			var $activeItem = $el.closest('ul').find('> li.active');

			var $menu = $el.find('.main-header__menu');
			var $content = $el.find('.main-header__menu-cols');

			if($content.find('input[type="text"]')) {
				setTimeout(function() {
					$content.find('input[type="text"]').focus();
				}, 500);
			}



			if($activeItem.length>0) {
				
				var $activeContent = $activeItem.find('.main-header__menu-cols');
				var $activeMenu = $activeItem.find('.main-header__menu');
				var activeHeight = $activeMenu.height();
				var activeOpacity = $activeMenu.css('opacity');

				$activeItem.removeClass('active');

				// get next menu height
				$menu.show();
				var menuHeight = $menu.height();
				$menu.hide();

				TweenMax.set($activeMenu, {display: 'none'});

				TweenMax.set($menu, {display: 'block', height: activeHeight, opacity: activeOpacity});
				TweenMax.set($content, {opacity: 0, top: 10});
				TweenMax.to($menu,  0.2, {height: menuHeight, opacity: 1, clearProps: "height"});
				TweenMax.to($content,  0.2, {opacity: 1, top: 0, delay: 0.1});

			} else {

				TweenMax.set($menu, {opacity:0, scaleY: 0.5, display: 'block'});
				TweenMax.set($content, {opacity:0, top: 10});

				TweenMax.to($menu, 0.1, {opacity:1});
				TweenMax.to($menu, 0.25, {scaleY: 1});
				TweenMax.to($content, 0.3, {opacity:1, top:0, delay: 0.2});

			}
			$el.addClass('active');
		}

		function hideMenu($el) {

			if(!$el.hasClass('active'))
				return;

			$el.removeClass('active');

			var $menu = $el.find('.main-header__menu');
			var $content = $el.find('.main-header__menu-cols');

			// TweenMax.set($menu, {opacity:0, scaleY: 0.5, display:'block', top: 10});
			// TweenMax.set($content, {opacity:0, top: 10});

			TweenMax.to($content, 0.1, {opacity:0});
			TweenMax.to($menu, 0.1, {opacity:0});
			TweenMax.set($menu, {scaleY:1, display: 'none', delay: 0.1});
		}

		function hideMenus() {
			hideMenu(_this.find('.main-header__main-nav > ul > li.active'));
		}
 


		init();
		return this;
	};

	BEHAVIOUR.mainNavMobile = function() {
		
		var _this = $(this);

		function init() {


			if (_this.data('__mainNavMobile'))
				return;
			_this.data('__mainNavMobile',true);


			_this.find('.main-nav-mobile__toggle').click(function(event) {
				event.preventDefault();
				var $this = $(this);
				var $parent = $this.parent();
				var $content = $parent.find('.main-nav-mobile__submenu');
				if($parent.hasClass('is-open')) {
					$content.slideUp();
				} else {
					$content.slideDown();
				}
				$parent.toggleClass('is-open');
			});
		}

		init();
		return this;
	};

	BEHAVIOUR.miniCart = function() {
		
		var _this = $(this);

		function init() {


			if (_this.data('__miniCart'))
				return;
			_this.data('__miniCart',true);

			_this.$cart = _this.find('.mini-cart');
			_this.cartTimeout = 0;
			_this.menuIcon = $('.main-header__icon-menu');

			_this.isOpen = false;

			
			_this.find('> a').click(function(event) {
				if(canShowMiniCart()) {
					event.preventDefault();
					if(_this.isOpen) {
						hideCart();
					} else {
						loadAndShowCart();
					}
				}
			});

			_this.on('touchstart click', function(event) {
				event.stopPropagation();
			});

			$('body').on('touchstart click', function(event) {
				hideCart();
			});

			$(document).on('menu:main-nav-open', function() {
				hideCart();
			});
		}

		function canShowMiniCart() {
			return _this.menuIcon.css('display') == "none";
		}

		function loadAndShowCart() {
			if(_this.isOpen)
				return;

			_this.isOpen = true;

			var def = loadRoute(_this.data('url'));
			def.done(function(data) {
				attachContent(data);
				initContent();
				showCart();
			});
		}

		function loadRoute(url) {
			var deferred = $.Deferred();
			$.ajax({
				url : url,
				dataType : 'text',
				processData : 'false',
				success : deferred.resolve
			});
			return deferred.promise();
		}

		function attachContent(r) {
			_this.$cart.html(r);
		}

		function initContent() {
			_this.$cart.find('.close').click(function() {
				remove($(this));
			});
		}

		function showCart() {

			$(document).trigger('menu:mini-cart-open');

			var $cart = _this.$cart;
			var $content = $cart.find('.mini-cart__content');

			TweenMax.set($cart, {opacity:0, scaleY: 0.5, display: 'block'});
			TweenMax.set($content, {opacity:0, top: 10});

			if(App.isDesktop)
				_this.$cart.find('.mini-cart__items').jScrollPane({
					verticalGutter: 10
				});

			TweenMax.to($cart, 0.1, {opacity:1});
			TweenMax.to($cart, 0.25, {scaleY: 1});
			TweenMax.to($content, 0.3, {opacity:1, top:0, delay: 0.2});
		}

		function hideCart() {

			// clearInterval(_this.cartTimeout);

			_this.isOpen = false;

			var $cart = _this.$cart;
			var $content = $cart.find('.mini-cart__content');

			TweenMax.to($content, 0.1, {opacity:0});
			TweenMax.to($cart, 0.1, {opacity:0});
			TweenMax.set($cart, {scaleY:1, display: 'none', delay: 0.1});
		}

		function remove($el) {
			var def = loadRoute($el.data('url'));
			def.done(function(data) {
				attachContent(data);
				initContent();
				loadCartTotal();
			});
		}

		function loadCartTotal() {
			$.ajax({
				url : _this.data('total-url'),
				dataType : 'json',
				success : function(r) {
					updateCartTotal(r.total);
				}
			});
		}

		function updateCartTotal(total) {
			var $cartNumber = $('.main-header__cart-total');
			var $header = $('.main-header');
			var className = 'cart-is-full';
			$cartNumber.text(total);
			if(parseInt(total)>0) {
				$header.addClass(className);
				TweenMax.set($cartNumber, {y: -10, opacity: 0});
				TweenMax.to($cartNumber, 0.3, {y: 0, opacity: 1});
			} else {
				TweenMax.set($cartNumber, {opacity: 0});
				$header.removeClass(className);
			}
		}

		init();
		return this;
	};

	BEHAVIOUR.modalCookie = function() {

		// require js-cookies
		
		var _this = $(this);

		function init() {

			if (_this.data('__modalCookie'))
				return;
			_this.data('__modalCookie',true);

			var cookieName = _this.data('cookie-name');
			
			if(Cookies.get(cookieName))
				return;


			_this.on('show.bs.modal', function(e) {
		    	centerModal();
		    	$(window).bind('resize.modal', function() {
		    		centerModal();
		    	});
			});

			_this.on('hide.bs.modal', function(e) {
		    	$(window).unbind('resize.modal');
			});

			_this.find('.modal-close, button').click(function() {
				Cookies.set(cookieName, '1', { expires: 30*3 });
			});

			setTimeout(function() {
				// check if any modal is open, otherwise skip the opening.
				if($('.modal.in').length===0)
					_this.modal('show');
			}, 15 * 1000);

		}

		function centerModal() {
	        var $clone = _this.clone().css('display', 'block').appendTo('body');
	        var top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2);
	        top = top > 0 ? top : 0;
	        top = top > 100 ? top-30 : top;
	        $clone.remove();
	        _this.find('.modal-content').css("margin-top", top);
		}

		init();
		return this;
	};

	BEHAVIOUR.modalToggle = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__modalToggle'))
				return;
			_this.data('__modalToggle',true);

			_this.$modal = $(_this.data('modal-target'));

			_this.$modal.on('show.bs.modal', function(e) {
		    	centerModal();
		    	$(window).bind('resize.modal', function() {
		    		centerModal();
		    	});
			});

			_this.$modal.on('hide.bs.modal', function(e) {
		    	$(window).unbind('resize.modal');
			});

			_this.click(function(event) {
				event.preventDefault();
				var data = _this.data('modal-data');
				if(data) {
					$.each(data, function(selector, value) {
						var el = _this.$modal.find(selector);
						if(el.length > 0) {
							if(el[0].hasAttribute('value')) {
								el.attr('value', value).change();
							} else {
								el.text(value);
							}
						}
					})
				}
				_this.$modal.modal('toggle');
			});

		}

		function centerModal() {
		    _this.$modal.each( function(i) {
		        var $clone = $(this).clone().css('display', 'block').appendTo('body');
		        var top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2);
		        top = top > 0 ? top : 0;
		        top = top > 100 ? top-30 : top;
		        $clone.remove();
		        $(this).find('.modal-content').css("margin-top", top);
		    });
		}

		

		init();
		return this;
	};

	BEHAVIOUR.scrollTo = function() {

		var _this = $(this);

		function init() {

			if (_this.data('__scrollTo'))
				return;
			_this.data('__scrollTo',true);

			var target = _this.data('target');
			var offset = _this.data('offset');

			if(!offset)
				offset = 0;

			_this.click(function(event) {
				event.preventDefault();
				TweenLite.to(window, 0.4, {scrollTo: {y:_this.data('target'), offsetY:offset }});
			});

		}


		init();
		return this;
	};
	
	BEHAVIOUR.searchSuggestions = function() {

		var _this = $(this);

		function init() {

			if (_this.data('__searchSuggestions'))
				return;
			_this.data('__searchSuggestions',true);

			_this.url = _this.data('action');

			_this.$resultContainer = _this.find('.search-suggestions');
			_this.$resultList = _this.find('.search-suggestions__list');
			_this.$resultTemplate = _this.find('.search-suggestions__item').clone();

			_this.find('input[type="text"]').on("keyup change", function() {
				var val = $(this).val();
				if(val.length>2) {
					startSearch(val);
				}
			});
		}

		function startSearch(keyword) {
			var ajax = loadResult(keyword);
			ajax.done(function(data){
				showResult(data);
			});

			// track search with facebook pixel
			if(window.fbq) {
				window.fbq('track', "Search", {search_string: keyword});
			}
		}

		function loadResult(keyword) {
			if(_this.xhr)
				_this.xhr.abort();
			var data = {};
			data[_this.find('input[type="text"]').attr('name')] = keyword;
			_this.xhr = $.ajax({
				url : _this.url,
				dataType : 'json',
				data : data,
				method : 'get'
			});
			return _this.xhr;
		}

		function showResult(data) {
			var result = data.result;
			if(result.length) {
				_this.$resultList.empty();
				$.each(result, function() {
					var $item = _this.$resultTemplate.clone();
					$item.find('[data-item="producer"]').text(this.producer);
					$item.find('[data-item="dosaggio"]').text(this.dosaggio);
					$item.find('[data-item="name"]').text(this.name);
					$item.find('[data-item="link"]').attr('href', this.link);
					$item.find('[data-item="image"]').attr('src', this.image);
					if(this.price) {
						$item.find('[data-item="price"]').text(this.price);
					}
					$item.appendTo(_this.$resultList);
				});
				_this.$resultContainer.show();
			} else {
				_this.$resultContainer.hide();
			}
		}

		init();
		return this;
	};

	BEHAVIOUR.share = function() {
		/*
			data-share-dialog: window|popup optional
			data-url: optional
			data-title: optional
			data-description: optional
			data-source: optional
		*/

		var _this = $(this);

		function init() {

			if (_this.data('__share'))
				return;
			_this.data('__share',true);

			_this.data('share-dialog', 'popup');
			
			_this.dialog = (_this.data('share-dialog') === undefined) ? 'window' : _this.data('share-dialog');

			_this._url = (_this.data('url') === undefined) ? location.href : _this.data('url');
			//_this.url = location.protocol + '//' + location.host + $('[data-role="page"].ui-page-active').jqmData('url');
			_this._title = (_this.data('title') === undefined) ? $('title').text() : _this.data('title');

			_this.find('.facebook').click(facebook);
			_this.find('.twitter').click(twitter);
			_this.find('.google-plus').click(gplus);
			_this.find('.linkedin').click(linkedin);
			_this.find('.email').click(email);
		}

		function facebook(event) {
			event.preventDefault();
			openPopup('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(_this._url), 'facebook-share-dialog', 550, 450);
		}

		function twitter(event) {
			event.preventDefault();
			openPopup('https://twitter.com/intent/tweet?url=' + encodeURIComponent(_this._url) + '&text=' + encodeURIComponent(_this._title), 'twitter-share-dialog', 550, 450);
		}

		function gplus(event) {
			event.preventDefault();
			openPopup('https://plus.google.com/share?url=' + encodeURIComponent(_this._url), 'facebook-share-dialog', 550, 450);
		}

		function linkedin(event) {
			event.preventDefault();
			openPopup('https://www.linkedin.com/shareArticle?url=' + encodeURIComponent(_this._url), 'linkedin-share-dialog', 550, 450);
		}

		function email(event) {
			event.preventDefault();
			var emailTo = '',
				subject = _this._title,
				body = _this._title + "\n\n" + _this._url + "\n\n";
			location.href = 'mailto:' + emailTo + "?Subject=" + escape(subject) + "&Body=" + escape(body);
		}

		function openPopup(url, title, w, h) {
			if (_this.dialog == "popup") {
				var left = (screen.width / 2) - (w / 2), top = (screen.height / 2) - (h / 2);
				return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
			} else {
				return window.open(url);
			}
		}

		init();
		return this;
	};

	BEHAVIOUR.story = function() {
		

		var _this = $(this);

		function init() {

			if (_this.data('__story'))
				return;
			_this.data('__story',true);

			$(document).on('productlist:loaded', function(event, $html) {
				_this.find('.container-fluid').replaceWith($html.find('section.story .container-fluid'));
				afterInit();
				_this.GUI();
			});

			afterInit();

		}

		function afterInit() {

			// duplicate cta
			$cta = _this.find('.story__cta');
			if($cta.length>0) {
				$cta.clone().prependTo(_this.find('.story__wrapper'));
			}

			// duplicate titles around..
			$title = _this.find('h1');
			_this.find('.story__slide .story__text, .story__title').each(function() {
				$title.clone().prependTo($(this));
			});

			// duplicate content for mobile
			var $overlayMobileContent = _this.find('.story__mobile').clone();

			_this.find('.story__slide .story__item').each(function(i) {

				var $this = $(this);
				var $clone = $this.clone();

				// skip first slide if it contains an image, because it's already in .story__mobile
				if(i===0 && $this.find('.story__image'))
					return;

				if($clone.find('.story__text').length>0)
					$clone.addClass("story__item--text");

				$clone.find('h1').remove();
				
				$clone.appendTo($overlayMobileContent);

			});

			$overlayMobileContent.find('.greyscale').removeClass('greyscale');

			_this.find('.overlay-mobile__container').append('<div class="container-fluid"></div>');
			_this.find('.overlay-mobile__container .container-fluid').append($overlayMobileContent);

			_this.find('.story__wrapper').hover(function() {
				 _this.find('.story__wrapper .greyscale').addClass('greyscale--disabled');
			}, function() {
				 _this.find('.story__wrapper .greyscale').removeClass('greyscale--disabled');
			});

 			if(_this.find('.swiper-slide').length<2) {
 				_this.find('.slider-nav, .story__read').hide();
				return;
 			}

			var storySwiper = new Swiper (_this.find('.swiper-container'), {
				slidesPerView: 'auto',
				loop: true,
				speed: 900,
				// slideToClickedSlide: true,
				nextButton: _this.find('.slider-nav__next'), prevButton: _this.find('.slider-nav__prev'), pagination: _this.find('.slider-nav__pages'), paginationType: 'custom',
    			paginationCustomRender: function(swiper, current, total) {
    				return '<span>'+current + '</span> / <span>' + total + '</span>';
    			},
    			onSlideChangeStart: function(swiper) {
    				var $activeImage = _this.find('.swiper-slide-active .story__image');
    				if($activeImage.length===0) {
    					_this.find('.story__title .padded-text').addClass('padded-text--transparent');
    				} else {
    					_this.find('.story__title .padded-text').removeClass('padded-text--transparent');
    				}
    			}
			});

			 _this.find('.swiper-slide').click(function() {
			 	if($(this).prev().hasClass('swiper-slide-active'))
			 		storySwiper.slideNext();
			 });

			
		}

		init();
		return this;
	};

	BEHAVIOUR.toggleClass = function() {
		
		var _this = $(this);

		function init() {

			if (_this.data('__classToggle'))
				return;
			_this.data('__classToggle',true);

			var options = _this.data('toggle-class');

			if(jQuery.type(options)!="object")
				return;
				
			if(options.selectors===undefined || options.classes===undefined)
				return;

			if(jQuery.type(options.selectors) != jQuery.type(options.classes))
				return;

			_this.click(function(event) {
				event.preventDefault();
				if(jQuery.type(options.selectors)=="array") {
					$.each(options.selectors, function(index, value) {
						toggle(value, options.classes[index]);
					});
				} else {
					toggle(options.selectors, options.classes);
				}
			});

		}

		function toggle(selector, className) {
			// console.log('toggling class:');
			// console.log(selector);
			// console.log(className);
			$(selector).toggleClass(className);
		}

		init();
		return this;
	};

	// GLOBAL_BEHAVIOURS.push("waypoints");

	$.fn.GUI = function()
	{
		$.each(BEHAVIOUR, function(i,v) {
			var name = BEHAVIOUR_PREFIX + i;
			if(!$.isFunction($.fn[name]))
				$.fn[name] = v;
			$("." + BEHAVIOUR_PREFIX + "-" + i).each(function() {
				$(this)[name]();
			});
		});
		if($.isArray(GLOBAL_BEHAVIOURS)) {
			$.each(GLOBAL_BEHAVIOURS, function(i,v) {
				$('html')[BEHAVIOUR_PREFIX + v]();
			});
		}		
		return this;
	};

	$(document).ready(function()
	{
		$(document).ready(function(){
		    $('[data-toggle="tooltip"]').tooltip(); 
		});
		$('html').GUI();
	});
	
	
	$(window).load(function()
	{
		$.windowLoaded = true;
	});
	
})(jQuery, window);